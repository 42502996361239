import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { CurrencyService } from '../../services/currency.service';
import { LocaleDefinition, TranslateService } from '../../services/translate.service';

@Component({
    selector: 'app-locale-settings',
    templateUrl: './locale-settings.component.html',
    styleUrls: ['./locale-settings.component.scss'],
    imports: [CommonModule, TranslatePipe],
})
export class LocaleSettingsComponent implements OnInit {
    selectedLanguage: LocaleDefinition;
    selectedCurrency: { label: string; value: string };

    constructor(
        private readonly translateService: TranslateService,
        private readonly actionSheetCtrl: ActionSheetController,
        private readonly currencyService: CurrencyService,
    ) {}

    ngOnInit() {
        this.setLocale(this.translateService.getLocale());
        this.translateService.localeChanged.subscribe({
            next: (locale: string) => {
                this.setLocale(locale);
            },
        });
        this.selectedCurrency = CurrencyService.currencies.find((c) => c.value === this.currencyService.getCurrency());
    }

    async openCurrencySelector() {
        const sheet = await this.actionSheetCtrl.create({
            header: this.translateService.get(_('Select currency')),
            buttons: CurrencyService.currencies.map((c) => ({
                text: c.label,
                handler: () => {
                    this.currencyService.setCurrency(c.value);
                    // Reload the page
                    window.location.reload();
                },
            })),
        });
        await sheet.present();
    }

    async openLanguageSelector() {
        const sheet = await this.actionSheetCtrl.create({
            header: this.translateService.get(_('Select language')),
            buttons: TranslateService.LANGUAGES.map((l) => ({
                text: l.name,
                // icon: `assets/images/flags/${l.flag}.png`,
                handler: () => {
                    this.setLanguage(l);
                },
            })),
        });
        await sheet.present();
    }

    async setLanguage(language: LocaleDefinition) {
        await LoadingUtils.show();
        await this.translateService.setLocale(language.code);
        // Reload the page
        window.location.reload();
    }

    private setLocale(locale: string) {
        console.log(`Locale changed to ${locale}`);
        this.selectedLanguage = TranslateService.LANGUAGES.find((language) => language.code === locale);
    }
}
